const countries = [
  {
    name: 'Iran',
    dial_code: '+98',
    emoji: '🇮🇷',
    id: 'IR',
  },
];

export default countries;
